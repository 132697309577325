import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";
import { DirwebStylesProps } from "./Dirweb";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		root: (props?: DirwebStylesProps) => ({
			marginBottom: props?.stickyFooterHeight
				? props.stickyFooterHeight + 10
				: theme.spacing(2),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.spacing(4),
				paddingRight: theme.spacing(4)
			}
		}),
		header: {
			textAlign: "center",
			[theme.breakpoints.up("md")]: {
				marginTop: theme.spacing(2)
			}
		},
		mainGrid: {
			padding: theme.spacing(2),
			[theme.breakpoints.up("md")]: {
				marginTop: theme.spacing(4),
				padding: theme.spacing(4)
			}
		},
		backLink: {
			textDecoration: "underline"
		},
		pageDescription: {
			fontSize: "95%",
			lineHeight: 1.5,
			fontWeight: 600,
			paddingRight: 12,
			paddingBottom: 12,
			paddingTop: 16
		},
		titleText: {
			[theme.breakpoints.down("sm")]: {
				margin: "auto 8px"
			}
		},
		headerIcon: {
			maxWidth: "300px",
			minWidth: "180px",
			width: "20%",
			paddingTop: "32px"
		}
	}),
	{ name: "DployDirwebFormStyles" }
);

export const mainTheme = (theme: Theme): Theme => {
	const overrides = {
		overrides: {
			DployFormSectionGroup: {
				sectionGroupWrapper: {
					padding: 0,
					paddingTop: 0,
					paddingBottom: 0
				},
				...theme.overrides?.DployFormSectionGroup
			},
			DployFormSection: {
				root: {
					"&:not(:last-child)": {
						[theme.breakpoints.down("sm")]: {
							marginBottom: theme.spacing(1)
						}
					}
				},
				headerMarginLiteral: {
					[theme.breakpoints.up("sm")]: {
						marginLeft: 4
					},
					[theme.breakpoints.up("md")]: {
						marginLeft: 24
					}
				},
				...theme.overrides?.DployFormSection
			},
			DployFormFieldContainer: {
				styleHelperText: {
					marginTop: 0,
					"& div > pre > code": {
						whiteSpace: "pre-line"
					}
				},
				...theme.overrides?.DployFormFieldContainer
			},
			DployFormLiteral: {
				marginNormal: {
					[theme.breakpoints.down("sm")]: {
						paddingLeft: 4
					},
					[theme.breakpoints.up("md")]: {
						paddingLeft: 24
					}
				},
				...theme.overrides?.DployFormLiteral
			}
		}
	};
	return merge({}, theme, overrides) as Theme;
};

export const actionsTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormPanel: {
			sectionGroupsContainer: {
				marginTop: theme.spacing(2),
				[theme.breakpoints.up("md")]: {}
			},
			...theme.overrides?.DployFormPanel
		},
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				[theme.breakpoints.up("md")]: {
					marginTop: theme.spacing(3)
				},
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			sectionGroup: {
				justifyContent: "center"
			},
			...theme.overrides?.DployFormSectionGroup
		},
		DployCalculationField: {
			field: {
				[theme.breakpoints.down("xs")]: {
					width: "100%"
				}
			},
			...theme.overrides?.DployCalculationField
		},
		DployFormFieldContainer: {
			root: {
				margin: theme.spacing(-0.5),
				[theme.breakpoints.up("md")]: {
					flexGrow: 1
				}
			},
			fieldWrapper: {
				[theme.breakpoints.down("xs")]: {
					flexGrow: 1
				}
			},
			...theme.overrides?.DployFormFieldContainer
		},
		MuiButton: {
			root: {
				whiteSpace: "nowrap" as const,
				width: "100%"
			},
			...theme.overrides?.MuiButton
		}
	} as any
});
